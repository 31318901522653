/*
 * @Author: Musa Tabitay
 * @Date: 2021-12-30 23:15:38
 * @LastEditTime: 2022-04-23 22:54:08
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from '@/utils/util'
import { wechatRedirect } from '@/api/user'

Vue.use(VueRouter)

const routes = [
  {
    path: '/detail/:parent_id/:type',
    name: 'detail',
    component: () => import('@/views/article/Detail'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/isMobile',
    name: 'isMobile',
    component: () => import('@/views/isMobile')
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

// 前置导航守卫
router.beforeEach((to, from, next) => {
  const openId = getCookie('openId')
  // 检查用户是否授权过
  if (to.meta.requiresAuth && !openId) {
    location.href = `${wechatRedirect}?url=${encodeURIComponent(location.href)}&scope=snsapi_userinfo`
  }

  next()
})

export default router
