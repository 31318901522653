<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { jssdk, getToken } from '@/api/user'
import { vipStatus } from '@/api/member'
import { getItem, setItem } from '@/utils/storage'

export default {
  name: 'App',
  data () {
    return {
      openId: ''
    }
  },
  mounted () {
    const openId = this.$cookies.get('openId')
    const userInfo = getItem('userInfo')
    this.openId = openId
    if (openId) this.getWechatConfig()
    if (openId && !userInfo) this.getUserInfo()
  },
  methods: {
    async vipStatus () {
      const userInfo = getItem('userInfo')
      if (userInfo.token) {
        const { data } = await vipStatus()
        setItem('vipStatus', data.status)
      }
    },
    async getUserInfo () {
      try {
        const { data } = await getToken({
          type: 103,
          account: this.openId
        })

        if (data?.token) {
          setItem('userInfo', data)
        }
      } catch (error) {
        console.error(error)
      }
    },

    async getWechatConfig () {
      try {
        const { data } = await jssdk()
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: data.jsApiList // 必填，需要使用的JS接口列表
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
